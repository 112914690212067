body{
  font-family: 'Calibri', Arial, Helvetica, sans-serif;
  line-height: 1.4;
}
.splide,
.splide__track{
  height: 100%;
}
.splide__pagination__page{
  margin: 0;
  width: 12px;
  height: 4px;
  border-radius: 12px;
  background-color: #fff;
  transform: scale(1);
  transition: 0.8s ease-in-out;
  transform-origin: right;
  pointer-events: none;
}
.splide__pagination__page.is-active{
  transform: scale(1);
  width: 40px;
}

/* .animate-slide {
  animation: slideFade 0.5s ease-in-out;
  transition: .5s ease-in-out;
} */

@keyframes slideFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}